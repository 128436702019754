<template>
    <div class="wrap">
        <div class="page-main" style="padding-bottom: 20px">
            <span @click="goback" class="goback">
                <i class="el-icon-back"></i>{{ $t('protectFile.backText') }}
            </span>
            <div class="shareInfo" @click="getShareInfo">
                <svg-icon data_iconName="share" className="obtn share" />
                {{
                    opusInfo.sharingStatus === 0
                        ? $t('protectFile.tableOperate.btn2')
                        : opusInfo.sharingStatus === 1
                        ? $t('protectFile.tableOperate.btn2')
                        : ""
                }}
            </div>
            
            <div class="detail_content">
                <div class="bg-container">
                    <div class="section top_cnt">
                        <div class="title">
                            <span class="sline"></span>
                            <strong>{{$t('fileDetail.certificate')}}</strong>
                        </div>
                        <div class="certificate">
                            <div class="image__preview">
                                <el-image
                                    class="pic"
                                    :src="opusInfo.pdfThumbnailUrl"
                                    :preview-src-list="[opusInfo.pdfThumbnailUrl]"
                                >
                                    <div slot="error" class="image-slot">
                                    <i class="el-icon-picture-outline"></i>
                                    </div>
                                </el-image>
                                <div class="download">
                                    <el-tooltip
                                        effect="dark"
                                        :content="$t('protectFile.tableChooseHeader.downCertificate')"
                                        placement="top"
                                    >
                                        <i class="el-icon-download" @click="download('tsa')"></i>
                                    </el-tooltip>
                                </div>
                            </div>
                        </div>
                        <div class="certificateInfo">
                            <p>
                                <span class="dt">{{$t('fileDetail.certificateNum')}}</span>
                                {{ opusInfo.pdfTsaSerialNo }}
                                <b @click="copy" class="copybtn" :data-clipboard-text="opusInfo.pdfTsaSerialNo">
                                    <el-tooltip class="item" effect="dark" :content="$t('fileDetail.copy')" placement="top">
                                        <i class="el-icon-document-copy"></i>
                                    </el-tooltip>
                                </b>
                            </p>
                            <p>
                                <span class="dt">{{$t('fileDetail.authenticationCode')}}</span> 
                                {{ opusInfo.fileHash }}
                                <b @click="copy" class="copybtn" :data-clipboard-text="opusInfo.fileHash">
                                    <el-tooltip class="item" effect="dark" :content="$t('fileDetail.copy')" placement="top">
                                        <i class="el-icon-document-copy"></i>
                                    </el-tooltip>
                                </b>
                            </p>
                            <p>
                                <span class="dt">{{$t('fileDetail.issuedTime')}}</span>
                                {{ opusInfo.tsaAt ? opusInfo.tsaAt : '' | formatDate }} (UTC+8)
                            </p>
                        </div>
                        <div class="original">
                            <div class="title">
                                <span class="sline"></span>
                                <strong>{{$t('fileDetail.original')}}</strong>
                            </div>
                            <div class="fileArea">
                                <div class="marker">
                                    <img 
                                        class="noUp" 
                                        src="@/assets/images/noUpEn.png" alt="" 
                                        v-if="opusInfo.sourceFileUploadType == 0 && $i18n.locale=='en'" 
                                    />
                                    <img 
                                        class="noUp" 
                                        src="@/assets/images/noUp.png" alt="" 
                                        v-else-if="opusInfo.sourceFileUploadType == 0" 
                                    />

                                    <img 
                                        class="noUp" 
                                        src="@/assets/images/upEn.png" alt="" 
                                        v-else-if="!suffix && !isVideo && !opusInfo.isExpired && $i18n.locale=='en'"
                                    />
                                    <img 
                                        class="noUp" 
                                        src="@/assets/images/up.png" alt="" 
                                        v-else-if="!suffix && !isVideo && !opusInfo.isExpired"
                                    />
                                </div>
                                <!-- 储存已过期 -->
                                <div class="preview_box" v-if="opusInfo.isExpired">
                                    <div class="preview_wrap">
                                        <div class="noLook">
                                            <p>{{$t('fileDetail.expired')}}</p>
                                        </div>
                                        <div class="operate">
                                            <el-tooltip
                                                effect="dark"
                                                :content="$t('protectFile.tableChooseHeader.downOriginal')"
                                                placement="top"
                                            >
                                                <i class="el-icon-download" @click="download"></i>
                                            </el-tooltip>
                                            <el-tooltip
                                                effect="dark"
                                                :content="$t('protectFile.tableChooseHeader.deleteOriginal')"
                                                placement="top"
                                            >
                                                <i class="el-icon-delete" @click="deleteFile"></i>
                                            </el-tooltip>
                                        </div>
                                    </div>
                                    <p>{{opusInfo.storeExpiredAt | formatYMD}}{{$t('fileDetail.expiresDate')}}</p>
                                </div>
                                <!-- 无原件，可上传 -->
                                <div class="upload_box" v-else-if="opusInfo.sourceFileUploadType == 0">
                                    <input
                                        type="file"
                                        class="uploadfile"
                                        @change="addfile($event)"
                                        ref="inputer"
                                    />
                                    <div class="addIco">
                                        <img src="@/assets/images/addIcon.png" alt="" />
                                        <p>{{$t('fileDetail.upOriginal')}}</p>
                                    </div>
                                </div>
                                <!-- 可预览图片原件 -->
                                <div class="preview_box" v-else-if="suffix">
                                    <div class="preview_wrap">
                                        <el-image
                                            class="pic"
                                            :src="opusInfo.sourceFileStorePathUrl"
                                            :preview-src-list="[opusInfo.sourceFileStorePathUrl]"
                                        >
                                        </el-image>
                                        <div class="operate">
                                            <el-tooltip
                                                effect="dark"
                                                :content="$t('protectFile.tableChooseHeader.downOriginal')"
                                                placement="top"
                                            >
                                                <i class="el-icon-download" @click="download"></i>
                                            </el-tooltip>
                                            <el-tooltip
                                                effect="dark"
                                                :content="$t('protectFile.tableChooseHeader.deleteOriginal')"
                                                placement="top"
                                            >
                                                <i class="el-icon-delete" @click="deleteFile"></i>
                                            </el-tooltip>
                                        </div>
                                    </div>
                                    <p>{{$t('fileDetail.dueDate')}}：{{opusInfo.storeExpiredAt | formatYMD}}</p>
                                </div>
                                <!-- 可预览视频原件 -->
                                <div class="preview_box" v-else-if="isVideo">
                                    <div class="preview_wrap">
                                        <video-player
                                            class="video-player vjs-custom-skin pic"
                                            ref="video"
                                            :playsinline="true"
                                            :options="playerOptions"
                                        ></video-player>
                                        <div class="playVideo" @click="playVideo">
                                        </div>
                                        <div class="operate">
                                            <el-tooltip
                                                effect="dark"
                                                :content="$t('protectFile.tableChooseHeader.downOriginal')"
                                                placement="top"
                                            >
                                                <i class="el-icon-download" @click="download"></i>
                                            </el-tooltip>
                                            <el-tooltip
                                                effect="dark"
                                                :content="$t('protectFile.tableChooseHeader.deleteOriginal')"
                                                placement="top"
                                            >
                                                <i class="el-icon-delete" @click="deleteFile"></i>
                                            </el-tooltip>
                                        </div>
                                    </div>
                                    <p>{{$t('fileDetail.dueDate')}}：{{opusInfo.storeExpiredAt | formatYMD}}</p>
                                </div>
                                <!-- 不可预览原件格式 -->
                                <div class="preview_box" v-else>
                                    <div class="preview_wrap">
                                        <div class="noLook">
                                            <img src="@/assets/images/questionIcon.png" alt="" />
                                            <p>{{$t('fileDetail.noPreview')}}</p>
                                        </div>
                                        <div class="operate">
                                            <el-tooltip
                                                effect="dark"
                                                :content="$t('protectFile.tableChooseHeader.downOriginal')"
                                                placement="top"
                                            >
                                                <i class="el-icon-download" @click="download" ref="downTip"></i>
                                            </el-tooltip>
                                            <el-tooltip
                                                effect="dark"
                                                :content="$t('protectFile.tableChooseHeader.deleteOriginal')"
                                                placement="top"
                                            >
                                                <i class="el-icon-delete" @click="deleteFile"></i>
                                            </el-tooltip>
                                        </div>
                                    </div>
                                    <p>{{$t('fileDetail.dueDate')}}：{{opusInfo.storeExpiredAt | formatYMD}}</p>
                                </div>
                            </div>
                            <p style="color:#999" v-if="opusInfo.storeType!==0">{{$t('fileDetail.fileSize')}}  {{ opusInfo.applyFileSize | fileSize}}</p>
                        </div>
                    </div>
                </div>                
                <div class="bg-container">
                    <div class="section bot_cnt">
                        <div class="title">
                            <span class="sline"></span>
                            <strong>{{$t('fileDetail.applyInformation')}}</strong>
                        </div>
                        <div class="applyInfo">
                            <p><span class="dt">{{$t('fileDetail.applicant')}}</span> {{ opusInfo.applyName }}</p>
                            <p><span class="dt">{{$t('fileDetail.applicationAccount')}}</span> {{ opusInfo.applyAccount }}</p>
                            <p><span class="dt">{{$t('fileDetail.iDType')}}</span> {{ applyIdType }}</p>
                            <p><span class="dt">{{$t('fileDetail.idNum')}}</span> {{ opusInfo.applyIdNumber }}</p>
                            <p><span class="dt">{{$t('fileDetail.fileName')}}</span> {{ opusInfo.opusName }}</p>
                            <p><span class="dt">{{$t('fileDetail.fileType')}}</span> 
                                {{$i18n.locale=='en'?opusInfo.fileEnTypeName : opusInfo.fileTypeName }}
                            </p>
                            <p>
                                <span class="dt">{{$t('fileDetail.statemen')}}</span>
                                {{ opusInfo.opusDescribe }}
                            </p>
                            <p>
                                <span class="dt">
                                    <span>{{$t('fileDetail.remarks')}}</span>
                                    <span v-show="!opusInfo.modified">
                                    <span style="color:#666">{{ opusInfo.applyRemark }}</span>
                                    <i
                                        class="el-icon-edit-outline editDetail"
                                        @click="updateRemarkShow"
                                    />
                                    </span>
                                </span>
                                <br />
                                <span v-show="opusInfo.modified">
                                    <el-input
                                    type="textarea"
                                    :placeholder="$t('fileDetail.remarksPlaceholder')"
                                    v-model="opusInfo.applyRemark"
                                    maxlength="100"
                                    show-word-limit
                                    style="margin-top: 10px;width:80%;margin-right:30px"
                                    :autosize="{ minRows: 4, maxRows: 6 }"
                                    >
                                    </el-input>
                                    <el-button
                                    class="editBtn"
                                    type="primary"
                                    icon="el-icon-check"
                                    @click="updateRemark"
                                    ></el-button>
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="zhe" v-if="zhe">
            <div class="progress">
                <el-progress type="circle" :percentage="percentage"></el-progress>
            </div>
        </div>
        <el-dialog
            :title="$t('fileDetail.upfileLoading')"
            :visible.sync="diaProgress"
            width="620px"
            :modal-append-to-body="false"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :before-close="overUpload"
            top="30vh"
            class="progress-dialog"
        >
            <div class="diaProgress">
                <el-progress
                    :text-inside="true"
                    :stroke-width="26"
                    :percentage="percentage"
                ></el-progress>
            </div>
        </el-dialog>
        <div class="mask" v-if="videoMask">
            <div class="video">
                <b class="close" @click="videoMask=false"><i class="el-icon-close"></i></b>
                <div class="video_body">
                    <video-player
                        class="video-player vjs-custom-skin"
                        ref="video"
                        :playsinline="true"
                        :options="playerOptions"
                    ></video-player>
                </div>
            </div>
        </div>
        <ShareInfo ref="shareInfo" @shareMethods="shareMethods" />
        <ShareAddress ref="shareAddress" @shareMethods="shareMethods" />
        <ShareModal ref="shareModal" :searchParam='{}' />
        <!-- 已过期且余额不足情况下，下载 -->
        <el-dialog :title="$t('protectFile.downNoEnoughDiaBox.title')" :visible.sync="downNoEnoughDiaBox" width="450px" class="myDialogNormal downDiaBox" :close-on-click-modal="false" :close-on-press-escape="false">
            <div :class="$i18n.locale=='en'? 'dialog_cnt dialog_cnt_en':'dialog_cnt'">
                <div class="icon"><i class="el-icon-warning"></i></div>
                <h3 v-if="userInfo.userType == 'ADMIN_ACCOUNT'">{{ $t('protectFile.downNoEnoughDiaBox.desParents') }}</h3>
                <h3 class="alignLeft" v-else>{{$t('protectFile.downNoEnoughDiaBox.desChild')}}</h3>
                <p>
                    {{$t('protectFile.downNoEnoughDiaBox.node1')}}
                    <b class="red">{{opusInfo.expiredDays}}</b>
                    {{$t('protectFile.downNoEnoughDiaBox.node2')}}
                    <b class="blue">{{opusInfo.storeTsCount}}</b>
                    {{$t('protectFile.downNoEnoughDiaBox.node3')}}
                </p>
            </div>
            <div slot="footer" class="dialog_footer">
                <el-button type="info" plain @click="downNoEnoughDiaBox=false" v-if="userInfo.userType == 'ADMIN_ACCOUNT'">
                    {{ $t('diaBtn.cancel') }}
                </el-button>
                <el-button class="copybtn" type="primary" @click="goRecharge('down')" v-if="userInfo.userType == 'ADMIN_ACCOUNT'">
                    {{ $t('diaBtn.recharge') }}
                </el-button>
                <el-button class="copybtn" type="primary" @click="downNoEnoughDiaBox=false" v-else>
                    {{ $t('diaBtn.know') }}
                </el-button>
            </div>
        </el-dialog>
        <!-- 身份子账号，文件已过期且主账号分配余额不足情况下，下载 -->
        <el-dialog :title="$t('protectFile.downNoEnoughDiaBox.title')" :visible.sync="downNoEnoughChildDiaBox" width="450px" class="myDialogNormal downDiaBox" :close-on-click-modal="false" :close-on-press-escape="false">
            <div class="dialog_cnt">
                <div class="icon"><i class="el-icon-warning"></i></div>
                <h3 class="alignLeft">{{$t('protectFile.downNoEnoughChildDiaBox.desParents')}}</h3>
                <p>
                    {{$t('protectFile.downNoEnoughChildDiaBox.node1')}}
                    <b class="red">{{opusInfo.expiredDays}}</b>
                    {{$t('protectFile.downNoEnoughChildDiaBox.node2')}}
                    <b class="blue">{{opusInfo.storeTsCount}}</b>
                    {{$t('protectFile.downNoEnoughChildDiaBox.node3')}}
                </p>
            </div>
            <div slot="footer" class="dialog_footer">
                <el-button class="copybtn" type="primary" @click="downNoEnoughChildDiaBox=false">{{ $t('diaBtn.know') }}</el-button>
            </div>
        </el-dialog>
        <!-- 已过期且余额足够情况下，下载 -->
        <el-dialog :title="$t('protectFile.downNoEnoughDiaBox.title')" :visible.sync="downEnoughDiaBox" width="450px" class="myDialogNormal downDiaBox" :close-on-click-modal="false" :close-on-press-escape="false">
            <div class="dialog_cnt">
                <div class="icon"><i class="el-icon-warning"></i></div>
                <h3>{{$t('protectFile.downEnoughDiaBox.desParents')}}</h3>
                <p>
                    {{$t('protectFile.downEnoughDiaBox.node1')}}
                    <b class="red">{{opusInfo.expiredDays}}</b>
                    {{$t('protectFile.downEnoughDiaBox.node2')}}
                    <b class="blue">{{opusInfo.storeTsCount}}</b>
                    {{$t('protectFile.downEnoughDiaBox.node3')}}
                </p>
            </div>
            <div slot="footer" class="dialog_footer">
                <el-button type="info" plain @click="downEnoughDiaBox=false">{{ $t('diaBtn.cancel') }}</el-button>
                <el-button class="copybtn" type="primary" @click="handlDownload">{{ $t('diaBtn.download') }}</el-button>
            </div>
        </el-dialog>
        <!-- 删除提示框 -->
        <el-dialog :title="$t('protectFile.downNoEnoughDiaBox.title')" :visible.sync="deleteDiaBox" width="450px" class="myDialogNormal downDiaBox" :close-on-click-modal="false" :close-on-press-escape="false">
            <div class="dialog_cnt">
                <div class="icon"><i class="el-icon-warning"></i></div>
                <h3 class="alignLeft">{{$t('protectFile.deleteDiaBox.des')}}</h3>
            </div>
            <div slot="footer" class="dialog_footer">
                <el-button type="info" plain @click="deleteDiaBox=false">{{ $t('diaBtn.cancel') }}</el-button>
                <el-button class="copybtn" type="primary" @click="handleDelete">{{ $t('diaBtn.confirm') }}</el-button>
            </div>
        </el-dialog>
        <!-- 上传提示框,余额够用 -->
        <el-dialog :title="$t('protectFile.downNoEnoughDiaBox.title')" :visible.sync="balanceEnoughDiaBox" width="450px" class="myDialogNormal downDiaBox" :close-on-click-modal="false" :close-on-press-escape="false" :before-close="clearInput">
            <div :class="$i18n.locale=='en'? 'dialog_cnt dialog_cnt_en':'dialog_cnt'">
                <h3>
                    {{$t('fileDetail.balanceEnoughDiaBox.node1')}}
                    <b class="blue">{{fileSize}}</b>
                    {{$t('fileDetail.balanceEnoughDiaBox.node2')}}
                    <b class="blue">{{tsCount}}</b>
                    {{$t('fileDetail.balanceEnoughDiaBox.node3')}}
                </h3>
            </div>
            <div slot="footer" class="dialog_footer">
                <el-button type="info" plain @click="cancelUpload">{{ $t('diaBtn.cancel') }}</el-button>
                <el-button class="copybtn" type="primary" @click="handleUpload">{{ $t('diaBtn.upload') }}</el-button>
            </div>
        </el-dialog>
        <!-- 上传提示框，余额不足 -->
        <el-dialog :title="$t('protectFile.downNoEnoughDiaBox.title')" :visible.sync="balanceNoEnoughDiaBox" width="450px" class="myDialogNormal downDiaBox" :close-on-click-modal="false" :close-on-press-escape="false" :before-close="clearInput">
            <div :class="$i18n.locale=='en'? 'dialog_cnt dialog_cnt_en':'dialog_cnt'">
                <h3 v-if="userInfo.userType == 'ADMIN_ACCOUNT'">{{$t('fileDetail.balanceNoEnoughDiaBox.des1')}}</h3>
                <h3 v-else>{{$t('fileDetail.balanceNoEnoughDiaBox.des2')}}</h3>
                <p>
                    {{$t('fileDetail.balanceEnoughDiaBox.node1')}}
                    <b class="blue">{{fileSize}}</b>
                    {{$t('fileDetail.balanceEnoughDiaBox.node2')}}
                    <b class="blue">{{tsCount}}</b>
                    {{$t('fileDetail.balanceEnoughDiaBox.node3')}}
                </p>
            </div>
            <div slot="footer" class="dialog_footer">
                <el-button type="info" plain @click="(balanceNoEnoughDiaBox=false,$refs.inputer.value=null)" v-if="userInfo.userType == 'ADMIN_ACCOUNT'">{{ $t('diaBtn.cancel') }}</el-button>
                <el-button class="copybtn" type="primary" @click="goRecharge('up')" v-if="userInfo.userType == 'ADMIN_ACCOUNT'">{{ $t('diaBtn.recharge') }}</el-button>
                <el-button class="copybtn" type="primary" @click="(balanceNoEnoughDiaBox=false,$refs.inputer.value=null)" v-else>{{ $t('diaBtn.know') }}</el-button>
            </div>
        </el-dialog>
        <!-- 身份子账号，主账号分配余额不足情况下上传 -->
        <el-dialog :title="$t('protectFile.downNoEnoughDiaBox.title')" :visible.sync="balanceNoEnoughChildDiaBox" width="450px" class="myDialogNormal downDiaBox" :close-on-click-modal="false" :close-on-press-escape="false">
            <div class="dialog_cnt">
                <h3>{{$t('fileDetail.balanceNoEnoughChildDiaBox.des')}}</h3>
                <p>
                    {{$t('fileDetail.balanceEnoughDiaBox.node1')}}
                    <b class="blue">{{fileSize}}</b>
                    {{$t('fileDetail.balanceEnoughDiaBox.node2')}}
                    <b class="blue">{{tsCount}}</b>
                    {{$t('fileDetail.balanceEnoughDiaBox.node3')}}
                </p>
            </div>
            <div slot="footer" class="dialog_footer">
                <el-button class="copybtn" type="primary" @click="balanceNoEnoughChildDiaBox=false">{{ $t('diaBtn.know') }}</el-button>
            </div>
        </el-dialog>
        <!-- 充值后回来的弹框 -->
        <el-dialog :title="$t('protectFile.downNoEnoughDiaBox.title')" :visible.sync="updateBalanceDiaBox" width="450px" class="myDialogNormal downDiaBox rechargeDiaBox" :close-on-click-modal="false" :close-on-press-escape="false"
        :before-close="clearInput">
            <div class="dialog_cnt">
                <h3>{{$t('protectFile.updateBalanceDiaBox.des')}}</h3>
            </div>
            <div slot="footer" class="dialog_footer">
                <el-button type="info" plain @click="goRecharge">{{ $t('diaBtn.recharge') }}</el-button>
                <el-button class="copybtn" type="primary" @click="download('goOn')" v-if="rechargeScene=='down'">{{ $t('diaBtn.download') }}</el-button>
                <el-button class="copybtn" type="primary" @click="UploadFile('goOn')" v-else>{{ $t('diaBtn.upload') }}</el-button>
            </div>
        </el-dialog>
    </div>
</template>
  
  <script>
import {
    findOpusInfo,
    complete,
    me,
    user,
    updateOpus,
    deleteOpusSourceFile,
    findBalance,
    lengthCount,
    supplementSts,
    payArrearageMoney,
    cancelStsUpload,
    getPermission
} from "@/api/service";
import { ossMultipartUpload,ossAbortMultipartUpload} from '@/utils/ossMultipartUpload'

import { formatDate , formatYMD, fileSize} from "@/utils/filter";
import { encrypt } from '@/utils/aes.js'
import CryptoJS from "crypto-js";
import { arrayBufferToWordArray } from "@/assets/js/buffer";
import ShareInfo from "@com/share/shareInfo";
import ShareAddress from "@com/share/shareAddress";
import ShareModal from "@com/share/shareModal";
import { getShareInfo } from "@/api/share";
import { mapState } from "vuex";

var pos = 0;
var end;
var chunkSize = 204800;
var lastprogress = 0;
var sha256 = CryptoJS.algo.SHA256.create();
var sha1 = CryptoJS.algo.SHA1.create();
var interval = null;
export default {
    data() {
        return {
            url: "",
            srcList: [
                "https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg",
                "https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg",
            ],
            serialNo: "",
            opusInfo: {},
            fil: {},
            fileHash: "",
            fileHashSha1: "",
            zhe: false,
            percentage: 0,
            diaProgress: false,
            videoMask:false,
            playerOptions: {
                playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
                autoplay: false, // 如果为true,浏览器准备好时开始回放。
                muted: false, // 默认情况下将会消除任何音频。
                loop: false, // 是否视频一结束就重新开始。
                preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                language: "zh-CN",
                aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                sources: [
                    // type: 'video/x-flv',
                    // type: "rtmp/mp4",
                    // type: "application/x-mpegURL",
                    // type: "video/mp4",
                ],
                poster: "", // 封面地址
                notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
                controlBar: {
                    timeDivider: true, // 当前时间和持续时间的分隔符
                    durationDisplay: true, // 显示持续时间
                    remainingTimeDisplay: false, // 是否显示剩余时间功能
                    fullscreenToggle: true, // 是否显示全屏按钮
                },
            },
            downEnoughDiaBox:false,
            downNoEnoughDiaBox:false,
            downNoEnoughChildDiaBox:false,
            deleteDiaBox:false,
            balanceNoEnoughDiaBox:false,
            balanceNoEnoughChildDiaBox:false,
            balanceEnoughDiaBox:false,
            updateBalanceDiaBox:false,
            tsCount:0,//扣戳个数
            rechargeScene:'',
            detectionStop:false,//是否停止上传
            storeId:'',//上传和取消接口用到
        };
    },
    filters: {
        formatDate: formatDate,
        formatYMD: formatYMD,
        fileSize:fileSize
    },

    components: { ShareInfo, ShareAddress, ShareModal },

    computed: {
        applyIdType() {
            switch (this.opusInfo.applyIdType) {
                case 101:
                    return this.$t('applySteps.idCard1');
                    break;
                case 102:
                    return this.$t('applySteps.idCard2');
                    break;
                case 103:
                    return this.$t('applySteps.idCard3');
                    break;
                case 104:
                    return this.$t('applySteps.idCard4');
                    break;
                case 110:
                    return this.$t('applySteps.idCard5');
                    break;
                case 111:
                    return this.$t('applySteps.idCard6');
                    break;
                case 112:
                    return this.$t('applySteps.idCard7');
                    break;
                case 109:
                    return this.$t('applySteps.idCard8');
                    break;
                case 113:
                    return this.$t('applySteps.idCard9');
                    break;
            }
        },
        suffix() {
            if (this.opusInfo.sourceFileStorePath) {
                let path = this.opusInfo.sourceFileStorePath;
                let index = path.lastIndexOf(".");
                let extension = path.substring(index + 1, path.length);
                let arry_format = ["png", "gif", "jpg", "jpeg"];
                if (arry_format.indexOf(extension) == "-1") {
                    return false;
                } else {
                    return true;
                }
            } else {
                return false;
            }
        },
        isVideo() {
            if (this.opusInfo.sourceFileStorePath) {
                let path = this.opusInfo.sourceFileStorePath;
                let index = path.lastIndexOf(".");
                let extension = path.substring(index + 1, path.length);
                let arry_format = ["mp4", "3gp", "ogg", "webm"];
                if (arry_format.indexOf(extension) == "-1") {
                    return false;
                } else {
                    return true;
                }
            } else {
                return false;
            }
        },
        fileSize(){
            let size=this.fil[0] ? Math.floor(this.fil[0].size) : 0;
            let num = 1024.00; //byte
            if (!size)
                return "";
            if (size < num)
                return size + "B";
            if (size < Math.pow(num, 2))
                return (size / num).toFixed(2) + "K"; //kb
            if (size < Math.pow(num, 3))
                return (size / Math.pow(num, 2)).toFixed(2) + "M"; //M
            if (size < Math.pow(num, 4))
                return (size / Math.pow(num, 3)).toFixed(2) + "G"; //G
            return (size / Math.pow(num, 4)).toFixed(2) + "T"; //T
        },
        access_token(){
            return this.$store.state.userInfo.me.accessToken
        },
        ...mapState({
            userInfo: (state) => state.userInfo.userInfo,
            meInfo: (state) => state.userInfo.me,
        }),
    },

    watch:{
        balanceNoEnoughChildDiaBox(newVal){
            if(!newVal){
                this.$refs.inputer.value=null;
            }
        },
    },

    async created() {
        this.init();
    },

    mounted() {},

    destroyed() {
        clearInterval(interval);
        interval=null;
    },

    methods: {
        async init() {
            this.serialNo = this.$route.query.serialNo;
            await this.getInfo(this.serialNo);
            if (this.isVideo) {
                let path = this.opusInfo.sourceFileStorePath;
                let index = path.lastIndexOf(".");
                let extension = path.substring(index + 1, path.length);
                this.playerOptions.sources.push({
                    src: this.opusInfo.sourceFileStorePathUrl,
                    type: `video/${extension}`,
                });
            }
        },
        async getInfo(data) {
            let res = await findOpusInfo(data);
            this.opusInfo = res;
            this.opusInfo.modified = false;
        },
        goback() {
            let param = this.$route.query;
            delete param.serialNo;
            this.$router.push({
                path: "/time/ProtectFile",
                query: param,
            });
        },
        //1.选择文件，计算hash
        addfile(e) {
            clearInterval(interval);
            interval=null;
            let inputDOM = this.$refs.inputer;
            this.fil = inputDOM.files;
            //let size = Math.floor(this.fil[0].size);
            if(!this.fil[0]){return false}
            this.percentage=0;
            this.zhe = true;
            pos = 0;
            end;
            chunkSize = 204800; //409600;//204800;
            lastprogress = 0;
            sha256 = CryptoJS.algo.SHA256.create();
            sha1 = CryptoJS.algo.SHA1.create();
            interval = setInterval(function () {
                me()
                    .then((res) => {
                        console.log("正在保活");
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }, 60000);
            this.progressiveRead(inputDOM.files[0]);
        },
        progressiveReadNext(file, reader) {
            var that = this;
            end = Math.min(pos + chunkSize, file.size);
            if (file.slice) {
                var blob = file.slice(pos, end);
            } else if (file.webkitSlice) {
                var blob = file.webkitSlice(pos, end);
            }
            reader.readAsArrayBuffer(blob);
            reader.onload = function (e) {
                pos = end;
                var wordArray = arrayBufferToWordArray(e.target.result);
                sha256.update(wordArray);
                sha1.update(wordArray);
                var progress = Math.floor((pos / file.size) * 100);
                if (progress > lastprogress) {
                    //console.log(progress)
                    that.percentage = progress;
                    lastprogress = progress;
                }
                if (pos < file.size) {
                    setTimeout(that.progressiveReadNext(file, reader), 0);
                } else {
                    that.fileHashSha1 = sha1.finalize().toString().toUpperCase();
                    that.fileHash = sha256.finalize().toString().toUpperCase();
                    setTimeout(function () {
                        that.zhe = false;
                        that.percentage = 0;
                        that.UploadFile();
                    }, 1000);
                    clearInterval(interval);
                    interval=null;
                }
            };
        },
        progressiveRead(file) {
            // 20KiB at a time
            pos = 0;
            chunkSize = 204800; //409600;//204800;
            lastprogress = 0;
            let reader = new FileReader();
            setTimeout(this.progressiveReadNext(file, reader), 0);
        },
        //2.比对hash，判断余额等是否符合上传条件。给出对应提示操作弹框。
        async UploadFile(str) {
            let that = this;
            if(that.fileHash!==that.opusInfo.fileHash){
                that.$message.error(this.$t('fileDetail.fileInconsistent'))
                that.$refs.inputer.value=null;
                return false;
            }
            this.updateBalanceDiaBox=false;
            let serialNo=that.opusInfo.pdfSerialNo;
            let fileSize=Math.floor(that.fil[0].size);
            try {
                //获取扣戳个数接口
                let {resultList}=await lengthCount([{
                    keyType:1,
                    fileSize,
                    key:serialNo,
                }])
                this.tsCount=resultList[0].storeCount;
                let {deductible,errorMessage} = await findBalance(this.tsCount);
                if(deductible==1){ //余额够扣给出提示继续上传
                    if((resultList[0].isFirstUpload && ((fileSize / Math.pow(1024.00, 2)).toFixed(2)<=200)) || (str=='goOn')){
                        this.handleUpload();
                    }else{
                        this.balanceEnoughDiaBox=true;
                    }
                }else if(deductible == 4){//身份主账号，余额不足
                    this.balanceNoEnoughDiaBox=true;
                }else if(deductible == 2){ //身份子账号，主账号余额不足
                    if((resultList[0].isFirstUpload && ((fileSize / Math.pow(1024.00, 2)).toFixed(2)<=200)) || (str=='goOn')){
                        this.handleUpload();
                    }else{
                        this.balanceNoEnoughDiaBox=true;
                    }
                }else if(deductible == 3){ //3 身份子账号，主账号分配次数达上限
                    if((resultList[0].isFirstUpload && ((fileSize / Math.pow(1024.00, 2)).toFixed(2)<=200)) || (str=='goOn')){
                        this.handleUpload();
                    }else{
                        this.balanceNoEnoughChildDiaBox=true;
                    }
                }else{
                    this.$message.error(errorMessage);
                    this.$refs.inputer.value = null;
                }
            } catch (error) {
                console.log(error)
            }
        },
        //3.开始上传，并轮询查询状态。
        async handleUpload(){
            this.balanceEnoughDiaBox=false;
            this.updateBalanceDiaBox=false;
            let serialNo=this.opusInfo.pdfSerialNo;
            let fileSize=Math.floor(this.fil[0].size);
            try {
                let res=await supplementSts(encrypt({
                    serialNo,
                    fileSize,
                    fileHash256: this.fileHash,
                    fileHashSha1: this.fileHashSha1,
                    partnerType:this.$getlocalStorage('queryData')?.partnerType==1? 1:0
                }))
                this.storeId=res.storeId;
                var { accessKey, secretKey, secretToken, bucket, key }=res.stsInfoDTO;
                this.diaProgress = true;
                this.detectionStop=false;
            } catch (error) {
                console.log(error);
                if(error.data.code == 4){
                    this.balanceNoEnoughDiaBox=true;
                }else if(error.data.code == 2){
                    this.balanceNoEnoughDiaBox=true;
                }else if(error.data.code == 3){
                    this.balanceNoEnoughChildDiaBox=true;
                }else{
                    this.$message.error(error.data.message)
                    this.$refs.inputer.value = null;
                }
                return false
            }
            try {
                await ossMultipartUpload({
                    fileHash:this.fileHash,
                    accessKey,
                    secretKey,
                    secretToken,
                    bucket,
                    key,
                    file:this.fil[0],
                    storeId:this.storeId,
                }, ({ percentage }) => {
                    this.percentage = percentage;
                })
                this.detection();
            } catch (error) {
                console.log(error)
            }
            
        },
        async detection() {
            let that = this;
            if(this.detectionStop) return;
            try {
                let res = await complete({
                    serialNo: that.opusInfo.pdfSerialNo,
                    storeId:this.storeId,
                });
                console.log(res);
                if (res.sourceFileUploadType == 0) {
                    setTimeout(() => {
                        that.detection();
                    }, 1000);
                } else {
                    that.$message({
                        message: that.$t('fileDetail.uploadSuccess'),
                        type: "success",
                    });
                    that.diaProgress = false;
                    that.init();
                    that.updateBalance();
                }
            } catch (err) {
                this.$message.error(err.data.message);
                this.diaProgress = false;
                this.detectionStop=true;
                this.$refs.inputer.value = null;
            }
        },
        //4.上传过程中可以手动关闭结束上传。
        async overUpload(done){
            try {
                if (this.percentage === 100) {
                    this.$message({
                        message: this.$t('browserText.info11'),
                        type: 'warning'
                    });
                } else {
                    clearInterval(interval) // 清空保活接口
                    interval = null
                    this.detectionStop=true;//轮询停止
                    await ossAbortMultipartUpload() // 取消oss请求
                    // 需要调用终止分片的接口 （已经上传到服务器的就不能被取消了）
                    await cancelStsUpload({
                        serialNo: this.opusInfo.pdfSerialNo,
                        storeId:this.storeId,
                    })
                    this.$store.commit('axiosCancel/clearAxiosPromiseCancelArr')
                    this.$refs.inputer.value = null;
                    done();
                } 
            } catch (error) {
                console.log(error);
                this.$message.error(error.data.message);
                this.diaProgress = false;
                this.$refs.inputer.value=null;
            }
            
        },

        clearInput(done){
            if(this.$refs.inputer){
                this.$refs.inputer.value=null;
            }
            done();
        },
        
        playVideo(){
            this.videoMask=true;
        },
        async download(str) {
            let that = this;
            this.updateBalanceDiaBox=false;
            if (str == "tsa") {
                that.downTSA(this.opusInfo.pdfSerialNo);
            } else {
                if(str == "goOn"){
                    let res = await findOpusInfo(this.serialNo);
                    this.opusInfo = res;
                }
                if(this.opusInfo.isExpired){//已过期
                    let {deductible} = await findBalance(this.opusInfo.storeTsCount);
                    if(deductible==1){ //余额够扣给出提示继续扣费下载
                        if(str == "goOn"){
                            await this.handlDownload();
                        }else{
                            this.downEnoughDiaBox=true; 
                        }
                    }else if(deductible == 4){//身份主账号，余额不足
                        this.downNoEnoughDiaBox=true;
                    }else if(deductible==2){//身份子账号，主账号余额不足
                        this.downNoEnoughDiaBox=true;
                    }else{//3 身份子账号，主账号分配次数达上限
                        this.downNoEnoughChildDiaBox=true;
                        // that.$message.error('该文件尚有未支付的存储费，您的时间戳已用完，请联系主账号再次分配')
                    }
                }else{//下载
                    this.downFile()
                }
            }
        },
        //下载证书
        async downTSA(serialNo) {
            let baseURL;
            if (process.env.NODE_ENV === "development") {
                baseURL = "/api/api";
            } else {
                baseURL = "/opus/api";
            }
            //window.open(baseURL + `/opus/download/file/${serialNo}`)
            let link = document.createElement("a");
            link.style.display = "none";
            link.target = "_blank";
            link.href = baseURL + `/opus/download/file/${serialNo}`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
        //下载原件
        downFile(){
            let link = document.createElement("a");
            link.style.display = "none";
            link.target = "_blank";
            link.href = this.opusInfo.sourceFileStorePathUrl;
            link.download = this.opusInfo.opusName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
        async handlDownload(){
            try {
                // 先调扣费接口
                await payArrearageMoney({
                    serialNo:this.opusInfo.pdfSerialNo,
                    partnerType:this.$getlocalStorage('queryData')?.partnerType==1? 1:0
                });
                let res = await findOpusInfo(this.serialNo);
                this.opusInfo = res;
                this.downFile();
                this.downEnoughDiaBox=false;
                this.updateBalance()
            } catch (error) {
                this.downEnoughDiaBox=false;
                if(error.data.code == 4){
                    this.downNoEnoughDiaBox=true;
                }else if(error.data.code == 2){
                    this.downNoEnoughDiaBox=true;
                }else{
                    this.downNoEnoughChildDiaBox=true;
                }
                return false
            }
        },
        cancelUpload(){
            this.balanceEnoughDiaBox=false;
            this.$refs.inputer.value = null;
        },
        async deleteFile(){
            if (this.meInfo.parentId !== "0") {
                let {permissionValue}=await getPermission({type:1});
                if(permissionValue===1){//不可以删除
                    this.$message.error(this.$t('protectFile.deleteChildMsg'))
                    return
                }
            }
            this.deleteDiaBox=true;
        },
        handleDelete(){
            deleteOpusSourceFile({serialNo:this.serialNo}).then((res)=>{
                this.deleteDiaBox=false;
                this.$message.success(this.$t('protectFile.deleteSuccess'));
                this.init();
            }).catch((err)=>{
                this.$message.error(this.$t('fileDetail.deleteFail'));
            })
        },
        copy() {
            var clipboard = new this.Clipboard(".copybtn");
            var that = this;
            clipboard.on("success", (e) => {
                // console.log('复制成功')
                // 释放内存
                that.$message({
                    message: this.$t('applyTimeToast.copyText'),
                    type: "success",
                });
                setTimeout(function () {
                    that.template = false;
                }, 2000);
                clipboard.destroy();
            });
            clipboard.on("error", (e) => {
                // 不支持复制
                that.$message.error(this.$t('browserText.info11'));
                // 释放内存
                clipboard.destroy();
            });
        },
        // 获取分享
        async getShareInfo() {
            let row = this.$route.query
            if (this.opusInfo.sourceFileUploadType === 0) {
                this.$refs.shareModal.handleShow(row, this.$t('shareModal.shareToast1'), 0)
                return
            }
            let res=await user();
            this.$store.commit('userInfo/setUserInfo', res);
            if (res.identificationStatus === 2) {
                this.$refs.shareModal.handleShow(row, this.$t('shareModal.shareToast2'), 1)
                return
            }
            // if (this.meInfo.parentId !== '0') {
            //   this.$refs.shareModal.handleShow(
            //     row,
            //     '子账号不可分享文件，请使用主账号分享',
            //     2
            //   )
            //   return
            // }
            this.opusInfo.serialNo = row.serialNo
            try {
                let shareRes = await getShareInfo({
                    serialNo: row.serialNo,
                })
                if (shareRes.shareInfoExist === 2) {
                    this.$refs.shareInfo.handleShow(this.opusInfo, shareRes)
                } else if (shareRes.shareInfoExist === 1) {
                    this.$refs.shareAddress.handleShow(shareRes)
                }
            } catch (error) {
                //该子账号无分享权限，请联系主账号分配权限。
                if(error.data.code==20000){
                    if(this.$i18n.locale=='en'){
                        this.$refs.shareModal.handleShow(row,'This sub-account has no permission to share. Please contact the main account to assign permissions.',2)
                    }else{
                        this.$refs.shareModal.handleShow(row,error.data.message,2)
                    }
                    return
                }
            }
        },
        // 更新备注
        async updateRemarkShow() {
            this.$set(this.opusInfo, "modified", true);
            this.$forceUpdate();
        },
        async updateRemark() {
            const { serialNo } = this.$route.query;
            const { applyRemark } = this.opusInfo;
            let res = await updateOpus({ serialNo, applyRemark });
            if (res.code === 200) {
                this.$message.success(this.$t('protectFile.updateSuccess'));
                this.$set(this.opusInfo, "modified", false);
                this.$forceUpdate();
            }
        },
        // 停止分享
        async shareMethods() {
            this.init();
        },
        goRecharge(rechargeScene){
            if(rechargeScene==='up'|| rechargeScene==='down'){
                this.rechargeScene=rechargeScene;
            }
            this.downNoEnoughDiaBox=false;
            this.balanceNoEnoughDiaBox=false;
            window.open(process.env.VUE_APP_ACCOUNTUI + '/userAccount/buy?sourceType=952310&partnerType='+(this.$getlocalStorage('queryData')?.partnerType==1? 1:0));
            if(rechargeScene){
                this.updateBalanceDiaBox=true;
            }
        },
        async updateBalance(){
            try {
                let balance = await findBalance(0)
                this.$store.commit('balance/setBalance', balance)
            } catch (error) {
                this.$message.error(this.$t('protectFile.selectBalance'))
            }
        },
    },
};
</script>
<style lang="less" scoped>
/deep/.el-progress--circle .el-progress__text, .el-progress--dashboard .el-progress__text{
    color:#fff
}
p{
    margin-bottom:12px;
}
.wrap{
    background: transparent !important;
}
.detail_content {
    .bg-container{
        padding-top: 30px;
        background: #fff;
        border-radius: 20px;
        margin-bottom: 20px;
        .section {
            width: 51%;
            max-width: 865px;
            margin: 0 auto;
            box-sizing: border-box;
            position: relative;
            .title {
                display: flex;
                align-items: center;
                .sline{
                    width: 5px;
                    height: 15px;
                    display: none;
                    background-color: #6288fb;
                    margin-right: 10px;
                    display: inline-block;
                    vertical-align: middle;
                }
                strong {
                    color: #333333;
                    font-size: 16px;
                    font-weight: 600;
                    margin-right: 20px;
                }
            }
            .certificate{
                .image__preview {
                    margin:0 auto;
                    width: 405px;
                    min-height: 573px;
                    position: relative;
                    .pic {
                        width: 100%;
                        margin-top: 20px;
                        img {
                            width: 100%;
                        }
                        /deep/.video-js{
                            height: 100% !important;
                        }
                    }
                    .download{
                        position: absolute;
                        bottom:0;
                        right:-34px;
                        font-size: 20px;
                        cursor: pointer;
                        &:hover{
                            color:#6288fb;
                        }
                    }
                }
            }
            .certificateInfo,.applyInfo{ 
                margin-top:40px;
                p {
                    color: #666;
                    .dt {
                        color: #333;
                        margin-top: 15px;
                        line-height: 25px;
                        white-space: wrap;
                        word-wrap: break-word;
                        word-break: break-all;
                        .editDetail {
                            font-size: 19px;
                            margin-top: 4px;
                            float: left;
                            transition: all 0.15s ease-in 0.1s;
                            cursor: pointer;
                            &:hover {
                                color: #648afb;
                            }
                        }
                        span {
                            float: left;
                        }
                    }
                }
            }
            .applyInfo{
                margin-top: 0;
            }
            .original{
                float: right;
                width: 185px;
                margin-right: -200px;
                margin-top: -378px;
                .fileArea{
                    margin-top:8px;
                    position: relative;
                    width: auto;
                    .marker{
                        position: absolute;
                        top:0;
                        right: 0;
                        z-index:2;
                    }
                    .upload_box{
                        position: relative;
                        background:#F5F5F5;
                        width: 185px;
                        height: 185px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        .uploadfile{
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            z-index: 2;
                            cursor: pointer;
                            opacity: 0;
                        }
                        div{
                            text-align: center;
                            p{
                                margin-top: 11px;
                            }
                        }
                        .addIco{
                            width: 100%;
                            position: relative;
                            p{
                                width: 100%;
                                position: absolute;
                                bottom:-40px;
                                text-align: center;
                            }
                        }
                        
                    }
                    .preview_box{
                        .preview_wrap{
                            position: relative;
                            .pic{
                                width: 185px;
                                height: 185px;
                                /deep/.video-js{
                                    height: 100% !important;
                                }
                            }
                            .noLook{
                                background:#F5F5F5;
                                width: 185px;
                                height: 185px;
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                justify-content: center;
                                text-align: center;
                                p{
                                    margin-top: 11px;
                                    white-space:normal !important;
                                }
                            }
                            .operate{
                                position: absolute;
                                bottom: 0;
                                left:201px;
                                font-size: 20px;
                                white-space: nowrap;
                                i{
                                    margin-right: 16px;
                                    cursor: pointer;
                                    &:hover{
                                        color: #6288fb;
                                    }
                                }
                            }
                            .playVideo{
                                position: absolute;
                                z-index: 2;
                                top:0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                background: rgba(0, 0, 0, 0);
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                color: #fff;
                                font-size: 45px;
                                cursor: pointer;
                            }
                        }
                        p{
                            white-space: nowrap;
                            color: #999999;
                            margin: 5px 0 0;
                        }
                    }
                }
            }

        }
        .top_cnt{
            padding-bottom: 20px;
        }
        .bot_cnt{
            padding-bottom: 20px;
            .applyInfo{
                padding-top:25px;
            }
        }
    }
    
}
.mask{
    z-index: 999;
    position: fixed;
    background: rgba(0,0,0,.5);
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow-y: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    .video{
        margin:0 auto 20px;
        width: 780px;
        background: #fff;
        border-radius: 4px;
        position: relative;
        .close{
            font-size: 25px;
            color: #fff;
            position: absolute;
            right: -40px;
            top: -20px;
            cursor: pointer;
        }
    }
}
.copybtn {
    cursor: pointer;
    margin-left: 15px;
    &:hover i {
        color: #6288fb;
    }
}
.zhe {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    .progress {
        position: fixed;
        width: 126px;
        height: 126px;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 9999;
        color: #fff;
    }
}
.shareInfo {
    display: flex;
    align-items: center;
    float: right;
    margin: 15px 27px 0 0;
    font-size: 14px;
    font-weight: 400;
    transition: all 0.15s ease-in 0.1s;
    cursor: pointer;
    &:hover {
        color: #648afb;
    }
}
.goback {
    display: flex;
    align-items: center;
    float: right;
    margin: 15px 27px 0 0;
    font-size: 14px;
    font-weight: 400;
    transition: all 0.15s ease-in 0.1s;
    cursor: pointer;
    &:hover {
        color: #6288fb;
    }
    i{
        vertical-align: middle;
        margin-right: 4px;
        font-size: 20px;
    }
}
.myDialogNormal{
    display: flex;
    flex-direction: column;
    justify-content: center;
    /deep/.el-dialog{
        border-radius: 4px;
        margin-top: 0;
        margin: auto !important;
    }
    /deep/ .el-dialog__header{
        border-bottom: 1px solid #D8D8D8;
        padding: 15px;
    }
    /deep/ .el-dialog__body{
        word-break: normal;
        flex: 1;
    }
    /deep/ .el-dialog__footer{
        border-top: 1px solid #D8D8D8;
        padding: 10px 20px 10px;
    }
    
}

.downDiaBox{
    /deep/.el-dialog{ 
        height: auto;
    }
    .dialog_cnt{
        text-align: center;
        .icon {
            display: flex;
            justify-content: center;
            color: #F3B752;
            text-align: center;
            font-size: 50px;
            margin-bottom: 19px;
        }
        h3{
            font-size: 16px;
            text-align:center;
        }
        .alignLeft{
            text-align:left;
        }
        .red{
            color: red;
        }
        .blue{
            color: #6288fb;
        }
    }
    .dialog_cnt_en{
        text-align: left;
        h3{
            text-align:left;
        }
    }
    
}
@media screen and (max-width: 1650px) {
    .detail_content {
        .section {
            width: 65%;
            .original{
                margin-right: -110px;
            }

        }
    }
}

@media screen and (max-width: 1500px) {
    .detail_content {
        .section {
            width: 60%;
            .certificate{
                .image__preview {
                    width: 305px;
                    min-height: 432px;
                }
            }
            .original{
                margin-right: -150px;
            }

        }
    }
}
</style>
  